import km from "Img/km.png";
import mk from "Img/mk.png";
import dh from "Img/dh.png";
import jw from "Img/jw.png";
import ss from "Img/ss.png";
import sm from "Img/sm.png";
import by from "Img/by.png";
import sy from "Img/sy.png";
import mojito from "Img/mojito.png";
import mojitto from "Img/mojitto.png";
import now from "Img/now.png";

const ProjectList = [
  {
    condition: true,
    img: mojitto,
    title: "Mojitto Global",
    desc: "모지또 글로벌 진출 버전",
    date: "22/12/12",
    link: [
      {
        ch: "iOS App",
        url:
          "https://apps.apple.com/us/app/mojitto-daily-emoji-diary/id1631717082",
      },
      {
        ch: "AOS App",
        url:
          "https://play.google.com/store/apps/details?id=co.teamblender.mojitto&pli=1",
      },
    ],
    person: {
      Bogus: [jw, "Product Manager", "Hello World 🌐"],
      Jenna: [sm, "UI/UX Designer", "모지또야 앞으로 글.로.벌하게 쉐킷하자 🌎"],
      Soogoon: [ss, "iOS Dev", "모지또, 세계 최고의 앱입니다. -팀쿡-"],
      Bakbang: [by, "AOS Dev", "Follow Your Heart 🧘‍♂️"],
      Ohtt: [sy, "iOS Dev", "중요한 건 꺾이지 않는 마음 ❤️‍🔥"],
    },
  },
  {
    condition: true,
    img: mojito,
    title: "모지또",
    desc: "데일리 이모지 다이어리",
    date: "20/12/29",
    link: [
      {
        ch: "iOS App",
        url:
          "https://apps.apple.com/kr/app/%EB%AA%A8%EC%A7%80%EB%98%90-%EB%8D%B0%EC%9D%BC%EB%A6%AC-%EC%9D%B4%EB%AA%A8%EC%A7%80-%EB%8B%A4%EC%9D%B4%EC%96%B4%EB%A6%AC/id1508866668",
      },
      {
        ch: "AOS App",
        url: "https://play.google.com/store/apps/details?id=com.blender.mojito",
      },
    ],
    person: {
      "Maximize.H": [dh, "Service Operator", "역시나 인생은 한 방이 아니다"],
      Bogus: [jw, "Product Manager", "어려운 만큼 많이 배웠다"],
      Jenna: [sm, "UI/UX Designer", "사람 욕심엔 끝이 없다"],
      Flodo: [mk, "Backend Dev", "개발자 구합니다"],
      Soogoon: [ss, "iOS Dev", "iOS의 길은 멀고도 험하다"],
      Moon: [km, "Team Improvement", "좋은 동료와 함께해서 좋았다"],
      Bakbang: [by, "AOS Dev", "끝은 새로운 시작. 끝난 줄 알았지? 그럴 리가~"],
    },
  },
];

export default ProjectList;
